import React from 'react'
import './SelectLanguage.css'
import { useNavigate } from 'react-router-dom';
import logo from '../img/Logo.svg'
import rus from '../img/rus.svg'
import usa from '../img/usa.svg'

export default function SelectLanguage({ i18n, setLang }) {
    const navigate = useNavigate();

    const changeLanguage = (newLanguage) => {
        i18n.changeLanguage(newLanguage); // Меняем язык в i18n
        localStorage.setItem('language', newLanguage); // Сохраняем в localStorage
        localStorage.setItem('lang', newLanguage); // Сохраняем выбранный язык
        setLang(newLanguage); // Обновляем состояние lang в родительском компоненте
        navigate('/home');
      };

  return (
    <div className='selectPage'>
        <div className="forLogo">
            <img src={logo} alt="Логотип meetell" />
        </div>
        <div className="selectContainer">
            <button className='selectBtn' style={{background: '#523DD8'}} onClick={() => changeLanguage('ru')}>
                <div className="forImgSelect">
                    <img src={rus} alt="Выбрать русский язык" />
                </div>
                <p>Россия</p>
            </button>
            <button className='selectBtn' onClick={() => changeLanguage('en')}>
                <div className="forImgSelect">
                    <img src={usa} alt="Выбрать русский язык" />
                </div>
                <p>USA</p>
            </button>
        </div>
    </div>
  )
}
