import './App.css';
import { Fragment } from "react";
import Main from '../appMain/Main';
import Question from '../appQuestion/Question';
import About from '../appStart/appAbout/About.jsx';
import Rules from '../appStart/appRules/Rules.jsx';
import Instruction from '../appStart/appInstruction/Instruction.jsx';
import Header from "../appHeader/Header";
import Footer from '../appFooter/Footer';
import { FooterProvider } from '../appFooter/FooterContext';
import Trip from '../appTrip/Trip';
import { loadTelegramWebApp } from "../telegram/telegram";
// import AddTripWalk from '../appAddTripWalk/AddTripWalk';
import {
  Routes,
  Route,
  useLocation,
  Navigate
} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import HelloScreen from '../appHelloScreen/HelloScreen.js';
import Carousel from '../appCarousel/Carousel.js';
import SelectLanguage from '../../appSelectLanguage/SelectLanguage.jsx';
import PreLoader from '../appPreLoader/PreLoader.js';
import Profile from '../appProfile/Profile.js';
import ProfileOther from '../appProfile/ProfileOther.jsx';
import Page404 from '../appPage404/Page404';
import question from '../../img/question.svg';
import rules from '../../img/rules.svg';
import manual from '../../img/manual.svg';
import smartphone from '../../img/smartphone.svg';
import Finished from '../appFinished/Finished.js';
import { useTranslation } from 'react-i18next';

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

function App() {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState({
    isLoadingData: true,
    isLoadingPhoto: true,
    isLoadingTrip: true,
  });
  const [register, setRegister] = useState(true);
  const [closeStart, setCloseStart] = useState(false);
  const [lang, setLang] = useState('ru');

  useEffect(() => {
    const imageUrls = [
      question,
      rules,
      manual,
      smartphone,
    ];

    preloadImages(imageUrls);
  }, []);

  useEffect(() => {

    const initializeApp = async () => {
      try {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.hash.substring(1));
        params.set('tgWebAppVersion', '7.7');
        const newUrl = `${url.origin}${url.pathname}#${params.toString()}`;
        window.history.replaceState(null, '', newUrl);
        console.log('Starting loadTelegramWebApp');
        await loadTelegramWebApp();
        window.Telegram.WebApp.disableVerticalSwipes();
        console.log('Finished loadTelegramWebApp');
      } catch (error) {
        console.error('Error during initialization:', error);
      }

      await fetchData();
      await fetchPhoto();
      await fetchTrip();
    };



    const fetchData = async () => {
      const user_id = localStorage.getItem('user_id');
      const user_name = localStorage.getItem('username');

      try {
        const userResponse = await fetch(`api/user?id=${user_id}&username=${user_name}`);
        const userInfo = await userResponse.json();
        localStorage.setItem('user_info', JSON.stringify(userInfo));
        setRegister(userInfo != null && userInfo.birthday != null ? true : false);
        setCloseStart(userInfo != null && userInfo.birthday != null ? true : false);

        const historyResponse = await fetch(`api/history?id=${user_id}`);
        const userHistory = await historyResponse.json();
        localStorage.setItem('user_history', JSON.stringify(userHistory));
        setLoading(prevStatus => ({ ...prevStatus, isLoadingData: false }));

      } catch (error) {
        localStorage.setItem('user_history', JSON.stringify([]));
        console.error('Error fetching data:', error);
      }

    };

    const fetchPhoto = async () => {
      const user_id = localStorage.getItem('user_id');

      try {
        const photoResponse = await fetch('api/photo?id=' + user_id);
        const userPhoto = await photoResponse.json();
        localStorage.setItem('user_photo', JSON.stringify(userPhoto));
        setLoading(prevStatus => ({ ...prevStatus, isLoadingPhoto: false }));

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchTrip = async () => {
      const today = new Date();
      const user_id = localStorage.getItem('user_id');
      try {
        const tripData = {
          city: 'spb',
          date: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          timeStart: today.getHours() + ":00",
          timeEnd: "23:30",
          timeTrip: "under_60",
          user_id: user_id
        };

        const tripResponse = await fetch('api/trips', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(tripData),
        });
        const tripResult = await tripResponse.json();
        localStorage.setItem('trips', JSON.stringify(tripResult));

      } catch (error) {
        localStorage.setItem('trips', JSON.stringify([]));
        console.error('Error fetching data:', error);
      }

      setLoading(prevStatus => ({ ...prevStatus, isLoadingTrip: false }));
    };

    initializeApp();
    let langStorage = localStorage.getItem('lang');
    if (!langStorage) {
      localStorage.setItem('lang', 'ru');
    } else {
      setLang(langStorage);
    }

    console.log(langStorage);
  }, []);

  const [hello, setHello] = useState(true);
  const [rateRoute, setRate] = useState(null);
  if (hello) {
    setTimeout(() => {
      setHello(false);

    }, 1000);
  }

  const Haptic = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    } else if (window.Telegram && window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
      webApp.HapticFeedback.impactOccurred("medium");
    }
  };
  const location = useLocation();
  const [style, setStyle] = useState({ display: 'block' })

  function closeGlass() {
    setStyle({ display: 'none' });
    setCloseStart(true);
  }

  return (
    <Fragment>
      <FooterProvider>
        <Header content={content} setContent={setContent} Haptic={Haptic} lang={lang} setLang={setLang} t={t} i18n={i18n}/>
        <Routes>
          <Route path='/' element={
            hello ? <HelloScreen t={t}/> :
              loading.isLoadingData || loading.isLoadingPhoto || loading.isLoadingTrip ? <PreLoader lang={lang}/> :
                !register ? <SelectLanguage i18n={i18n} setLang={setLang}/> :
                  <Navigate to="/home" replace />
          } />
          <Route path="/home" element={<Main setRate={setRate} register={register} closeStart={closeStart} style={style} closeGlass={closeGlass} t={t} />} />
          <Route path="/rate_route" element={<Finished rateRoute={rateRoute} t={t}/>} />
          <Route path='/question' element={<Question t={t}/>} />
          <Route path='/rules' element={<Rules t={t}/>} />
          <Route path='/instruction' element={<Instruction t={t}/>} />
          <Route path='/about' element={<About t={t}/>} />
          <Route path='/trips' element={<Trip setContent={setContent} content={content} t={t}/>} />
          <Route path='/profile' element={<Profile t={t}/>} />
          <Route path='/profile/:userId' element={<ProfileOther t={t}/>} />
          <Route path='/*' element={<Page404 t={t}/>} />
          {/* <Route path='/addTrip' element={<AddTripWalk />} /> */}
        </Routes>
        {!location.pathname.startsWith('/profile') && <Footer Haptic={Haptic} t={t}/>}
      </FooterProvider>
    </Fragment>
  );
}

export default App;
